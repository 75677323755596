import { MaskedTextField } from "@fluentui/react";
import { Space } from "antd";

import { FilterComponentProps } from "../types";

import { isObjectEmpty } from "@/utils/IsObjectEmpty";

export function MaskedText({
  filterConfigs,
  selectedTerms,
  visibleTerms,
  setVisibleTerms,
  setSelectedTerms,
  mask
}: FilterComponentProps): JSX.Element {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value;

    setVisibleTerms(prevState => ({
      ...prevState,
      [filterConfigs.filterQuery as string]: rawValue
    }));

    const conditionalSearch = filterConfigs.customConditional
      ? filterConfigs.customConditional(rawValue)
      : filterConfigs.valueFormatter(rawValue) !== "";

    if (conditionalSearch) {
      setSelectedTerms(prevState => ({
        ...prevState,
        [filterConfigs.filterQuery as string]:
          filterConfigs.valueFormatter(rawValue)
      }));
    } else if (
      !isObjectEmpty(selectedTerms) &&
      selectedTerms[filterConfigs.filterQuery as string]
    ) {
      setSelectedTerms(prevState => {
        const updatedState = { ...prevState };
        delete updatedState[filterConfigs.filterQuery as string];
        return updatedState;
      });
    }
  };

  return (
    <Space direction="vertical" size="small">
      <p style={{ marginBottom: "0px" }}>{filterConfigs.label}</p>

      <MaskedTextField
        type={filterConfigs.inputType ?? filterConfigs.type}
        disabled={filterConfigs.disabled ? true : false}
        placeholder={filterConfigs.placeholder}
        onChange={handleInputChange}
        style={{
          minWidth: filterConfigs?.minWidth
        }}
        mask={mask}
      />
    </Space>
  );
}
