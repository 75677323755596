import { IAdditionalOfferPrice } from "@/components/Settings/AdditionalComponents/types";
import { transformToCurrencyString } from "@/utils/TransformToCurrencyString";

export function calcMinValue(
  additionals: IAdditionalOfferPrice[],
  value: number
): string {
  // Calcular o total das taxas de serviço obrigatórias
  const requiredServiceFees = calculateRequiredServiceFees(additionals);
  // Encontrar o adicional "isUnique": true mais barato
  const cheapestUniqueService = findCheapestUniqueService(additionals);
  // Calcular o total incluindo o serviço "isUnique" mais barato
  const totalWithUnique =
    requiredServiceFees + parseFloat(cheapestUniqueService.monthlyFee);

  // Somar o valor da oferta
  const totalWithUniqueAndValue = totalWithUnique + value;

  // Transformar o total em uma string de moeda
  return transformToCurrencyString(totalWithUniqueAndValue);
}

// Função auxiliar para calcular o total das taxas de serviço obrigatórias
const calculateRequiredServiceFees = (
  services: IAdditionalOfferPrice[]
): number => {
  return services
    .filter(service => service.additionalOffer?.required)
    .reduce((total, service) => total + parseFloat(service.monthlyFee), 0);
};

// Função auxiliar para encontrar o adicional "isUnique": true mais barato
const findCheapestUniqueService = (
  services: IAdditionalOfferPrice[]
): IAdditionalOfferPrice => {
  const uniqueServices = services.filter(
    service => service.additionalOffer?.additional.additionalCategory.isUnique
  );
  return uniqueServices.reduce((minService, currentService) => {
    return parseFloat(currentService.monthlyFee) <
      parseFloat(minService.monthlyFee)
      ? currentService
      : minService;
  }, uniqueServices[0]); // Inicializamos com o primeiro adicional para garantir que temos um valor para comparar
};
