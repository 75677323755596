import { format } from "date-fns";

export const formatDate = (date: string): string => {
  const dateFormatted = new Intl.DateTimeFormat("pt-BR").format(new Date(date));

  return dateFormatted;
};

export const newFormatDate = (date: string, dateFormat: string): string => {
  if (date) {
    return format(new Date(date), dateFormat);
  } else {
    return "";
  }
};
