import { getTheme } from "@fluentui/react";
import { Badge } from "antd";
import styled from "styled-components";

const theme = getTheme();

export const ImageLogo = styled.img`
  height: 30px;
  margin: 20px 0;
`;

export const StyledBadgeRibbon = styled(Badge.Ribbon)`
  top: -15px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  max-width: 9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;

  &:hover {
    overflow: visible;
    max-width: unset;
  }
`;

export const PlanButton = styled.button<{
  isActive?: boolean;
  width?: string;
  minWidth?: string;
}>`
  font-size: 14px;
  font-weight: 600;
  background: ${props =>
    props.isActive ? theme.palette.themeSecondary : theme.palette.neutralLight};
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  color: ${props => (props.isActive ? "white" : "initial")};
  width: ${props => (props.width ? props.width : "")};
  min-width: ${props => (props.minWidth ? props.minWidth : "")};

  &:hover {
    background: ${theme.palette.themeSecondary};
    color: white;
  }
`;
