import { getTheme } from "@fluentui/react/lib/Styling";
import styled from "styled-components";

const theme = getTheme();

export const ItemTitle = styled.p`
  font-weight: 600;
`;

export const ItemInUseTitle = styled.span`
  position: absolute;
  top: -8px;
  left: 10px;
  font-weight: 600;
  background: ${theme.palette.white};
  padding: 0 10px;
  color: ${theme.palette.themeDark};
  user-select: none;
`;

export const ItemBox = styled.div<{ isItemActive?: boolean }>`
  position: relative;
  padding: ${props => (props.isItemActive ? `10px` : "")};
  border: ${props =>
    props.isItemActive ? `1px solid ${theme.palette.neutralTertiaryAlt}` : ""};
  flex-grow: 1;
`;
