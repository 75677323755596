import { Property } from "csstype";
import styled from "styled-components";

type ContractInfoValueProps = {
  maxWidth?: Property.MaxWidth;
  cursor?: Property.Cursor;
};

export const ContractInfoTitle = styled.p`
  font-weight: 600;
`;

export const ContractInfoValue = styled.div<ContractInfoValueProps>`
  margin-top: 2px;
  max-width: ${props => props.maxWidth && props.maxWidth};
  cursor: ${props => props.cursor && props.cursor};
`;
