import styled from "styled-components";

export const Scroll = styled.div`
  padding: 0px 18px;
  max-height: 600px;
  overflow-y: auto;
  width: 100%;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #d4d4d4;
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #b4b4b4;
    border-radius: 8px;
    -webkit-appearance: none;
  }
`;

export const InvoiceContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  background-color: #ffffff;
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132),
    0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
  padding: 1.25rem;
  border-radius: 1rem;
  column-gap: 1.25rem;
  min-height: 370px;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  margin: 0 1rem;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p.title {
    color: #395ca3;
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.75rem;
  }
`;

export const Title = styled.h1`
  font-size: 18px;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 5px;

  & > h1 {
    display: flex;
    flex-direction: column;
    align-items: start;
    span {
      font-size: 12px;
      color: rgb(57, 92, 163);
    }
  }
`;

export const TotalInvoiceValue = styled.h1`
  font-size: 18px;
  border-top: 1px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  text-align: right;
  margin-bottom: 0;
  h1 {
    margin-bottom: 0;
  }
  span {
    color: rgb(57, 92, 163);
    font-size: 12px;
    font-weight: bold;
  }
`;

interface SubtitleProps {
  value?: number;
}

export const Subtitle = styled.h2<SubtitleProps>`
  display: flex;
  font-size: 14px;
  padding-left: 15px;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 1px dotted lightgray;
  }

  p.value {
    color: ${({ value }) =>
      value !== undefined && value <= 0 ? "#b71d1d" : undefined};
  }

  span {
    display: flex;
    color: rgb(57, 92, 163);
    font-size: 12px;
    padding: 2px;
    font-weight: bold;
    margin-left: 2px;

    p {
      color: #000;
      font-weight: normal;
      margin: 0 0.25rem;
    }
  }
`;

export const Subitem = styled.span`
  font-size: 14px;
  padding: 0 2rem;
  display: flex;
  border-bottom: 1px dotted lightgray;
  flex-direction: row;
  justify-content: space-between;
`;

export const AccordionButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
`;

export const AccordionContent = styled.div`
  margin-top: 10px;
`;
