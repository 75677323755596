import { Drawer } from "antd";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CloseSidebarMenu } from "@/common/actions/sidebarMenu";
import { SidebarMenuPanelProps } from "@/components/Shared/Layouts/types/Sidebar.types";
import { RootState } from "@/core/store/rootReducer";

export function SidebarMenuPanel({
  theme,
  HeaderContent,
  MainContent,
  FooterContent,
  mainLinks,
  footerLinks
}: SidebarMenuPanelProps): JSX.Element {
  const { isMenuOpen } = useSelector((state: RootState) => state.sidebarMenu);
  const dispatch = useDispatch();

  const closeMenu = () => {
    dispatch(CloseSidebarMenu());
  };

  const handleResize = useCallback(() => {
    if (isMenuOpen && window.innerWidth > 639) {
      closeMenu();
    }
    //eslint-disable-next-line
  }, [isMenuOpen]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return (
    <Drawer
      onClose={closeMenu}
      title={<HeaderContent theme={theme} closeMenu={closeMenu} />}
      footer={
        <FooterContent
          theme={theme}
          closeMenu={closeMenu}
          links={footerLinks}
          isMenuOpen={isMenuOpen}
        />
      }
      visible={isMenuOpen}
      getContainer={false}
      closeIcon={null}
      width="280px"
      placement="left"
      bodyStyle={{ padding: 0 }}
      headerStyle={{ padding: 0 }}
      footerStyle={{ padding: 0 }}
      destroyOnClose
    >
      <MainContent
        closeMenu={closeMenu}
        theme={theme}
        links={mainLinks}
        isMenuOpen={isMenuOpen}
      />
    </Drawer>
  );
}
