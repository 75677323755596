import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  Stack,
  PrimaryButton
} from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";

import { IDocumentEletronicSignature } from "../../interfaces/ITerm.interface";

import SignatureTable from "@/components/Contracts/DisplayContractInfo/ContractSignatures/SignatureTable";
import { DefaultCardTitle } from "@/components/Contracts/DisplayContractInfo/styles";
import { Card } from "@/components/Shared/Card";
import { useApi } from "@/core/libs/api/react-query/useApi";

interface ISignaturesCard {
  documentId: number;
  eletronicSignature?: IDocumentEletronicSignature;
}

export const SignaturesCard = ({
  documentId,
  eletronicSignature: electronicSignature
}: ISignaturesCard) => {
  const { execute } = useApi();

  const [canResendSignatureRequest, setCanResendSignatureRequest] =
    useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const resendSignatures = useCallback(async () => {
    const paramsData = { id: documentId };
    await execute({
      url: `terms/${documentId}/resend-signatures`,
      method: "POST",
      notificationMessage: "Reenviando assinaturas do termo",
      params: Object(paramsData)
    });
  }, [documentId, execute]);

  useEffect(() => {
    setCanResendSignatureRequest(
      electronicSignature?.document?.signers?.some(
        signer => signer.sign_as === "transferee" && !signer.signature
      )
    );
  }, [electronicSignature]);

  const showConfirmResendSignatureRequest = (): void => {
    setIsDialogVisible(true);
  };

  const handleConfirm = () => {
    resendSignatures();
    setIsDialogVisible(false);
  };

  const handleClose = () => {
    setIsDialogVisible(false);
  };

  return (
    <Stack verticalFill>
      <Stack horizontal horizontalAlign="space-between">
        <DefaultCardTitle level={4}>
          <Icon iconName="OpenEnrollment" style={{ marginRight: 10 }} />
          Assinaturas
        </DefaultCardTitle>
        <DefaultButton
          disabled={!canResendSignatureRequest}
          onClick={showConfirmResendSignatureRequest}
        >
          Reenviar
        </DefaultButton>
      </Stack>
      <Card height="inherit">
        <div style={{ height: "100%" }}>
          <SignatureTable signatures={electronicSignature} />
        </div>
      </Card>
      <Dialog
        hidden={!isDialogVisible}
        onDismiss={handleClose}
        dialogContentProps={{
          type: DialogType.normal,
          title:
            "Você tem certeza que deseja reenviar as assinaturas pendentes?",
          subText:
            "As assinaturas pendentes serão reenviadas para todos que ainda não assinaram."
        }}
        modalProps={{
          isBlocking: false
        }}
      >
        <DialogFooter>
          <PrimaryButton onClick={handleConfirm} text="Reenviar" />
          <DefaultButton onClick={handleClose} text="Cancelar" />
        </DialogFooter>
      </Dialog>
    </Stack>
  );
};
