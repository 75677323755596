import { Grid } from "antd";
import { MutableRefObject, ReactNode } from "react";

import { DrawerSize } from "./enums";
import { StyledDrawer } from "./styles";

import { breakpointSizes } from "@/common/constants";

const { useBreakpoint } = Grid;

export type CustomDrawerProps = {
  onCloseDrawer: () => void;
  isOpen: boolean;
  children: ReactNode;
  /**
   * Drawer container reference to insert footer content
   */
  drawerContainerRef?: MutableRefObject<HTMLDivElement>;
  action: string;
  module: string;
  /**
   * Default value is true
   */
  hasTitle?: boolean;
  /**
   * Default value is small
   */
  size?: keyof typeof DrawerSize;
};

function isSelectedSizeBiggerThanBreakpoint(
  currentBreakPoint: number,
  selectedSize: number | string
) {
  return Number(selectedSize) >= currentBreakPoint ? "100%" : selectedSize;
}

export function CustomDrawer({
  isOpen,
  hasTitle = true,
  action,
  module,
  children,
  drawerContainerRef,
  onCloseDrawer,
  size
}: CustomDrawerProps): JSX.Element {
  const screens = useBreakpoint();
  const currentBreakpoint = Object.entries(screens).reduce(
    (breakpoint, [breakpointName, value]) => {
      if (value) breakpoint = breakpointName;
      return breakpoint;
    },
    ""
  );

  const CustomTitle = (
    <div className="custom-drawer-header">
      <span>
        {action} {module}
      </span>
    </div>
  );

  return (
    <div ref={drawerContainerRef}>
      <StyledDrawer
        title={hasTitle ? CustomTitle : null}
        width={
          size
            ? isSelectedSizeBiggerThanBreakpoint(
                breakpointSizes[currentBreakpoint],
                DrawerSize[size]
              )
            : isSelectedSizeBiggerThanBreakpoint(
                breakpointSizes[currentBreakpoint],
                DrawerSize.small
              )
        }
        onClose={onCloseDrawer}
        visible={isOpen}
        bodyStyle={{ paddingBottom: 80 }}
        getContainer={drawerContainerRef ? drawerContainerRef.current : false}
        destroyOnClose
      >
        {children}
      </StyledDrawer>
    </div>
  );
}
