import { Icon, Stack, TooltipHost } from "@fluentui/react";

import { DefaultCardTitle } from "../styles";

import * as S from "./styles";

import { Card } from "@/components/Shared/Card";
import { TranslatedSignatureType } from "@/modules/Contracts/enums";

interface ContractInfoProps {
  contract: any;
  title: any;
  padding?: any;
  isInsidePanel: boolean;
}

const tokens = {
  sectionStack: {
    childrenGap: 10,
    padding: 20
  },
  headingStack: {
    childrenGap: 10
  }
};

export function ContractInfo({
  contract,
  title,
  isInsidePanel
}: ContractInfoProps): JSX.Element {
  const createdAtDate = new Date(contract.createdAt);
  const updatedAtDate = new Date(contract.updatedAt);

  const buParent = contract.businessUnit?.parent;
  const names = [
    buParent?.parent?.name,
    buParent?.name,
    contract.businessUnit.name
  ].filter(Boolean);
  const buName = names.join(" -> ");

  return (
    <Stack verticalFill>
      <Stack
        horizontal
        className="ms-sm12"
        style={{ marginBottom: "1vh", height: 35 }}
      >
        <DefaultCardTitle level={4}>
          <Icon iconName="ClipboardList" style={{ marginRight: 10 }} />
          {title}
        </DefaultCardTitle>
      </Stack>

      <Card borderRadius="1rem" padding="1rem">
        <Stack horizontal wrap tokens={tokens.sectionStack}>
          <Stack.Item
            grow
            className={`ms-sm12 ${!isInsidePanel ? "ms-lg5" : ""}`}
          >
            <Stack tokens={tokens.headingStack}>
              <Stack.Item>
                <S.ContractInfoTitle>Tipo de Assinatura:</S.ContractInfoTitle>
                <S.ContractInfoValue>
                  {TranslatedSignatureType[contract?.signature?.type]}
                </S.ContractInfoValue>
              </Stack.Item>

              <Stack.Item>
                <S.ContractInfoTitle>Empresa:</S.ContractInfoTitle>
                <S.ContractInfoValue>{buName}</S.ContractInfoValue>
              </Stack.Item>

              <Stack.Item>
                <S.ContractInfoTitle>Autor do Contrato:</S.ContractInfoTitle>
                <S.ContractInfoValue>
                  {contract.user.fullName}
                </S.ContractInfoValue>
              </Stack.Item>

              <Stack.Item>
                <S.ContractInfoTitle>Criado em:</S.ContractInfoTitle>
                <TooltipHost
                  content={`às ${createdAtDate.getHours()}h ${createdAtDate.getMinutes()}m e ${createdAtDate.getSeconds()}s`}
                  calloutProps={{ gapSpace: 15 }}
                  styles={{ root: { display: "inline-block" } }}
                >
                  <S.ContractInfoValue maxWidth="max-content" cursor="default">
                    {createdAtDate.toLocaleDateString("pt-BR")}
                  </S.ContractInfoValue>
                </TooltipHost>
              </Stack.Item>

              <Stack.Item>
                <S.ContractInfoTitle>Atualizado em:</S.ContractInfoTitle>
                <TooltipHost
                  content={`às ${updatedAtDate.getHours()}h ${updatedAtDate.getMinutes()}m e ${updatedAtDate.getSeconds()}s`}
                  calloutProps={{ gapSpace: 15 }}
                  styles={{ root: { display: "inline-block" } }}
                >
                  <S.ContractInfoValue maxWidth="max-content" cursor="default">
                    {updatedAtDate.toLocaleDateString("pt-BR")}
                  </S.ContractInfoValue>
                </TooltipHost>
              </Stack.Item>
            </Stack>
          </Stack.Item>

          <Stack.Item
            grow
            className={`ms-sm12 ${!isInsidePanel ? "ms-lg5" : ""}`}
          >
            <Stack tokens={tokens.headingStack}>
              <Stack.Item>
                <S.ContractInfoTitle>Categoria:</S.ContractInfoTitle>
                <S.ContractInfoValue>
                  {contract.serviceCategory.name}
                </S.ContractInfoValue>
              </Stack.Item>

              <Stack.Item>
                <S.ContractInfoTitle>Benefício prestado:</S.ContractInfoTitle>
                <S.ContractInfoValue>
                  {contract.service.name}
                </S.ContractInfoValue>
              </Stack.Item>

              <Stack.Item>
                <S.ContractInfoTitle>Entrega:</S.ContractInfoTitle>
                <S.ContractInfoValue>
                  {contract.shipping
                    ? `
                        Frete
                        ${contract.shipping?.name}
                        - Prazo de entrega
                        ${contract.shipping?.deliveryTime}
                        ${contract.shipping?.deliveryTime > 1 ? "dias" : "dia"}
                        - Valor
                        ${toLocaleString(contract.shipping?.price)}`
                    : "Não foram encontrados dados sobre a entrega."}
                </S.ContractInfoValue>
              </Stack.Item>

              <Stack.Item>
                <S.ContractInfoTitle>Time comercial:</S.ContractInfoTitle>
                <S.ContractInfoValue>
                  {contract.salesTeam?.name
                    ? `${contract.salesTeam?.name}`
                    : " — "}
                </S.ContractInfoValue>
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </Card>
    </Stack>
  );
}

function toLocaleString(value) {
  return Number(value).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL"
  });
}
