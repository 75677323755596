import { DirectionalHint } from "@fluentui/react";
import Stack from "@fluentui/react/lib/components/Stack/Stack";

import { PlanButton, StyledBadgeRibbon } from "./styles";

import { OffersToolTip } from "@/components/Shared/OffersToolTip/OffersToolTip";
import { Plan, PlanOfferItem } from "@/modules/Offers/types/PlansOffers.types";

type PlanCardWithBadge = {
  plan: PlanOfferItem;
  selectedPlan?: Plan;
  selectedCarrier?: PlanOfferItem;
  setSelectedPlan?: (plan: Plan) => void;
  setSelectedCarrier?: (carrier: PlanOfferItem) => void;
  openModal: () => void;
};
interface IBadgeOption {
  name: string;
  text: string;
  color: string;
}

const badgeOptions: IBadgeOption[] = [
  { name: "tim fixo", text: "PBX TIM", color: "#063b88" },
  { name: "vivo fixo", text: "PBX VIVO", color: "#8821ba" },
  { name: "claro fixo", text: "PBX CLARO", color: "#e20000" },
  { name: "pbx 55", text: "55PBX", color: "#448757" },
  { name: "12 meses", text: "12 meses", color: "#00ad56" },
  { name: "black friday 2024", text: "Black Friday", color: "#000" },
  { name: "mês da mulher 2025", text: "Mês da Mulher", color: "#FF1493" },
  { name: "tim controle", text: "Controle", color: "#000080" },
  { name: "vivo controle", text: "Controle", color: "#000080" },
  { name: "natal 2024", text: "Natal", color: "#b71c1c" },
  { name: "travel", text: "Travel", color: "#00ad56" },
  { name: "pré", text: "Pré-Pago", color: "#00ad56" }
];

const getBadgeProps = (planName: string) => {
  const option = badgeOptions.find(option =>
    planName.toLowerCase().includes(option.name)
  );

  if (option) {
    return option;
  }

  return null;
};

export const PlanCardWithBadge = ({
  plan,
  selectedPlan,
  selectedCarrier,
  setSelectedPlan,
  setSelectedCarrier,
  openModal
}: PlanCardWithBadge) => {
  const handleSetSelectedPlan = (plan: Plan, carrier: PlanOfferItem) => {
    if (plan.id === selectedPlan?.id) {
      setSelectedCarrier(undefined);
      return setSelectedPlan(undefined);
    }

    handleSetSelectedCarrier(carrier);
    return setSelectedPlan(plan);
  };

  const handleSetSelectedCarrier = (carrier: PlanOfferItem) => {
    if (selectedCarrier?.id !== carrier.id) {
      return setSelectedCarrier(carrier);
    }
  };

  const sortPlansByDuration = (plans: Plan[]) => {
    const plans12Months = [];
    const plans24Months = [];
    const otherPlans = [];

    plans.forEach(plan => {
      if (plan?.offer?.fidelityDuration === 12) {
        plans12Months.push(plan);
      } else if (plan?.offer?.fidelityDuration === 24) {
        plans24Months.push(plan);
      } else {
        otherPlans.push(plan);
      }
    });

    const sortedPlans = [];

    const maxLength = Math.max(plans12Months.length, plans24Months.length);
    for (let i = 0; i < maxLength; i++) {
      if (i < plans12Months.length) {
        sortedPlans.push(plans12Months[i]);
      }

      if (i < plans24Months.length) {
        sortedPlans.push(plans24Months[i]);
      }
    }

    return [...sortedPlans, ...otherPlans];
  };

  const sortedPlans = sortPlansByDuration(plan.plans);

  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 20, padding: "30px 15px" }}
      style={{ width: "100%" }}
      wrap
      horizontalAlign="center"
    >
      {sortedPlans?.map((planItem, index) => {
        if (planItem?.data) {
          const badgeProps = getBadgeProps(planItem?.name);
          return (
            <div key={planItem.id} style={{ width: "40%" }}>
              {badgeProps ? (
                <StyledBadgeRibbon
                  text={badgeProps.text}
                  color={badgeProps.color}
                >
                  <OffersToolTip
                    plan={planItem}
                    position={
                      index % 2 === 0
                        ? DirectionalHint.leftCenter
                        : DirectionalHint.rightCenter
                    }
                  >
                    <PlanButton
                      id={`button-plan-${planItem.id}`}
                      minWidth="100px"
                      width="100%"
                      isActive={planItem.id === selectedPlan?.id}
                      onClick={() => {
                        handleSetSelectedPlan(planItem, plan);
                        openModal();
                      }}
                    >
                      {planItem?.data} GB
                      {planItem?.offer?.bonusData?.value > 0 &&
                        ` + ${planItem?.offer?.bonusData?.value} GB`}
                    </PlanButton>
                  </OffersToolTip>
                </StyledBadgeRibbon>
              ) : (
                <OffersToolTip
                  plan={planItem}
                  position={
                    index % 2 === 0
                      ? DirectionalHint.leftCenter
                      : DirectionalHint.rightCenter
                  }
                >
                  <PlanButton
                    id={`button-plan-${planItem.id}`}
                    minWidth="100px"
                    width="100%"
                    isActive={planItem.id === selectedPlan?.id}
                    onClick={() => {
                      handleSetSelectedPlan(planItem, plan);
                      openModal();
                    }}
                  >
                    {planItem?.data} GB
                    {planItem?.offer?.bonusData?.value > 0 &&
                      ` + ${planItem?.offer?.bonusData?.value} GB`}
                  </PlanButton>
                </OffersToolTip>
              )}
            </div>
          );
        }
        return null;
      })}
    </Stack>
  );
};
