import { Typography } from "antd";
import styled from "styled-components";

const { Title } = Typography;

type PaymentCardWrapperProps = {
  isInsidePanel?: boolean;
};
export const DefaultCardTitle = styled(Title)`
  margin-bottom: 1vh;
  padding: 0px 8px;
`;

export const PaymentCardWrapper = styled.div<PaymentCardWrapperProps>`
  display: ${props => (props.isInsidePanel ? "block" : "flex")};
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  column-gap: 1rem;
`;
