import { DefaultButton, Icon, Stack, getTheme } from "@fluentui/react";

import { DefaultCardTitle } from "@/components/Contracts/DisplayContractInfo/styles";
import { Card } from "@/components/Shared/Card";

interface IDocumentsCardProps {
  uploads: unknown[];
  canUpload: boolean;
  setIsVisible?: (bool: boolean) => void;
  isSendingDocumentStep?: boolean;
  canSendDocument?: boolean;
}

export const DocumentsCard = ({
  uploads,
  canSendDocument,
  isSendingDocumentStep,
  setIsVisible,
  canUpload
}: IDocumentsCardProps): JSX.Element => {
  const theme = getTheme();

  return (
    <>
      <Stack>
        <DefaultCardTitle level={4}>
          <Icon iconName="DocumentSet" style={{ marginRight: 10 }} />
          Documentos
        </DefaultCardTitle>
      </Stack>

      <Card height="inherit">
        <Stack style={{ height: "100%" }}>
          <Stack.Item styles={{ root: { height: "100%" } }}>
            {uploads.length > 0 && canUpload ? (
              <></>
            ) : (
              <Stack
                tokens={{ padding: 40 }}
                horizontalAlign="center"
                verticalAlign="center"
                verticalFill
              >
                <Icon
                  iconName="DocumentSearch"
                  style={{ fontSize: 80, color: "#6b6b6b" }}
                />

                <span
                  style={{
                    fontSize: 20,
                    color: "#6b6b6b",
                    textAlign: "center"
                  }}
                >
                  Nenhum documento foi encontrado.
                </span>
                {canSendDocument && setIsVisible && (
                  <DefaultButton
                    id="upload-documents-button"
                    style={{ marginTop: 15 }}
                    onClick={() => setIsVisible(true)}
                    styles={{
                      root: { borderColor: theme.palette.themeDark },
                      flexContainer: { color: theme.palette.themeDark }
                    }}
                  >
                    <Icon
                      iconName="CloudUpload"
                      style={{
                        marginRight: 10,
                        fontSize: 18
                      }}
                    />
                    Anexar documento(s)
                  </DefaultButton>
                )}
              </Stack>
            )}
          </Stack.Item>
        </Stack>
      </Card>
    </>
  );
};
