import { Icon, Stack } from "@fluentui/react";

import { DefaultCardTitle } from "../styles";

import { InputContainer } from "./styles";

import { Card } from "@/components/Shared/Card";
import { DateField } from "@/core/libs/form-builder/lib/date-field";

interface ContractRoamingProps {
  travelDate: Date;
  setTravelDate: (data: Date) => void;
}

export const ContractRoaming = ({
  travelDate,
  setTravelDate
}: ContractRoamingProps): JSX.Element => {
  const today = new Date();

  return (
    <Stack verticalFill>
      <DefaultCardTitle level={4}>
        <Icon
          iconName="AirTickets"
          style={{ marginRight: 10, position: "relative", top: 1 }}
        />
        Roaming internacional
      </DefaultCardTitle>

      <Card style={{ padding: "2rem" }}>
        <InputContainer>
          <div>
            <DateField
              label="Data de início da viagem"
              value={travelDate}
              placeholder={"Selecione uma data"}
              setFieldValue={(_, value) => {
                if (typeof value === "string") {
                  setTravelDate(new Date(value));
                } else {
                  setTravelDate(value);
                }
              }}
              name={"travelDate"}
              disabled={false}
              type={"DD/MM/YY"}
              errorMessage={"Data inválida"}
              isRequired={true}
              minDate={today}
            />
          </div>
        </InputContainer>
      </Card>
    </Stack>
  );
};
