import { DefaultButton, getTheme, Icon, Stack, Text } from "@fluentui/react";
import { useEffect, useState } from "react";

import * as S from "./styles";

import { Card } from "@/components/Shared/Card";
import { CustomDrawerFooter } from "@/components/Shared/CustomDrawer";
import { Loader } from "@/components/Shared/Loader";
import { AccountsService } from "@/core/libs/api";
import { useApiRequest } from "@/hooks/useApiRequest";
import {
  AccountContactType,
  AccountContactTypeTranslated,
  AccountDocumentTranslated,
  AccountDocumentType,
  AccountType,
  FieldsProps
} from "@/modules/Accounts/types/Accounts.types";
import { TranslatedMaritalStatus } from "@/modules/Contracts/enums";
import { ViewClientProps } from "@/modules/Contracts/types";
import { cpfCnpjFormatter } from "@/utils/FormatCPForCNPJ";
import { formatISOStringToDateString } from "@/utils/FormatISOStringToDateString";
import { phoneNumberFormatter } from "@/utils/PhoneNumberFormatter";

const accountService = AccountsService();
const theme = getTheme();

export type ClientInfoProps = {
  groupName: string;
  iconName: string;
  fields: {
    key: string | number;
    title?: string;
    value: string | JSX.Element;
  }[];
};

function buildClientInfo(
  client: FieldsProps,
  {
    addressIdInUse,
    checkingAccountIdInUse,
    contactIdInUse,
    documentIdInUse,
    secondaryDocumentIdInUse
  }
) {
  const clientInfo = [];

  if (client.accountType === AccountType.PHYSICAL) {
    clientInfo.push({
      groupName: "Dados",
      iconName: "Page",
      fields: [
        {
          key: "fullName",
          title: "Nome:",
          value: client.fullName
        },
        {
          key: "birthDate",
          title: "Data de nascimento:",
          value: formatISOStringToDateString(client.birthDate)
        },
        {
          key: "maritalStatus",
          title: "Estado Civil:",
          value: TranslatedMaritalStatus[client.maritalStatus?.name]
        },
        {
          key: "occupation",
          title: "Ocupação:",
          value: client.occupation
        }
      ]
    });
  } else {
    clientInfo.push({
      groupName: "Dados",
      iconName: "Page",
      fields: [
        {
          key: "fullName",
          title: "Razão Social:",
          value: client.fullName
        }
      ]
    });
  }

  clientInfo.push({
    groupName: "Documentos",
    iconName: "DocumentSet",
    fields: client.documents.map(document => ({
      key: document.documentType,
      title: `${AccountDocumentTranslated[document.documentType]}:`,
      value: (
        <S.ItemBox
          isItemActive={
            documentIdInUse === document.id ||
            secondaryDocumentIdInUse === document.id
          }
        >
          {documentIdInUse === document.id ||
          secondaryDocumentIdInUse === document.id ? (
            <S.ItemInUseTitle>Em uso</S.ItemInUseTitle>
          ) : null}
          {document.documentType === AccountDocumentType.CPF ||
          document.documentType === AccountDocumentType.CNPJ
            ? cpfCnpjFormatter(document.documentNumber)
            : document.documentNumber}
        </S.ItemBox>
      )
    }))
  });

  if (client.accountType === AccountType.LEGAL) {
    clientInfo.push({
      groupName: "Responsáveis Legais",
      iconName: "Group",
      fields: client.legalRepresentatives.map((representative, index) => ({
        key: representative.id,
        value: (
          <Stack
            horizontal
            verticalAlign="center"
            tokens={{ childrenGap: 15, padding: "10px 0 0 0" }}
            style={{
              borderTop:
                index > 0 && `1px solid ${theme.palette.neutralQuaternary}`
            }}
            grow
          >
            <Stack.Item>
              <S.ItemTitle>{`Representante ${index + 1}:`}</S.ItemTitle>
            </Stack.Item>
            <Stack>
              <Stack horizontal tokens={{ childrenGap: 5 }}>
                <S.ItemTitle>Nome do Representante Legal:</S.ItemTitle>
                <span>{representative.fullName}</span>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 5 }}>
                <S.ItemTitle>Email:</S.ItemTitle>
                <span>{representative.email}</span>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 5 }}>
                <S.ItemTitle>WhatsApp:</S.ItemTitle>
                <span>
                  {formatISOStringToDateString(representative.whatsapp)}
                </span>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 5 }}>
                <S.ItemTitle>CPF:</S.ItemTitle>
                <span>{cpfCnpjFormatter(representative.document)}</span>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 5 }}>
                <S.ItemTitle>Data de nascimento:</S.ItemTitle>
                <span>
                  {formatISOStringToDateString(representative.birthDate)}
                </span>
              </Stack>

              {representative.role ? (
                <Stack horizontal tokens={{ childrenGap: 5 }}>
                  <S.ItemTitle>Cargo na empresa:</S.ItemTitle>
                  <span>{representative.role}</span>
                </Stack>
              ) : null}
            </Stack>
          </Stack>
        )
      }))
    });
  }

  clientInfo.push(
    {
      groupName: "Dados de Contato",
      iconName: "ContactCard",
      fields: client.contacts.map(contact => ({
        key: contact.contactType,
        title: `${AccountContactTypeTranslated[contact.contactType]}:`,
        value: (
          <S.ItemBox isItemActive={contactIdInUse === contact.id}>
            {contactIdInUse === contact.id ? (
              <S.ItemInUseTitle>Em uso</S.ItemInUseTitle>
            ) : null}
            {contact.contactType === AccountContactType.PHONE_NUMBER ||
            contact.contactType === AccountContactType.WHATSAPP
              ? phoneNumberFormatter(contact.contact)
              : contact.contact}
          </S.ItemBox>
        )
      }))
    },
    {
      groupName: "Endereços",
      iconName: "Home",
      fields: client.addresses.map((address, index) => ({
        key: address.id,
        value: (
          <Stack
            horizontal
            verticalAlign="center"
            tokens={{ childrenGap: 15, padding: "10px 0 0 0" }}
            style={{
              borderTop:
                index > 0 && `1px solid ${theme.palette.neutralQuaternary}`
            }}
            grow
          >
            <Stack.Item>
              <S.ItemTitle>{`Endereço ${index + 1}:`}</S.ItemTitle>
            </Stack.Item>
            <S.ItemBox isItemActive={addressIdInUse === address.id}>
              {addressIdInUse === address.id ? (
                <S.ItemInUseTitle>Em uso</S.ItemInUseTitle>
              ) : null}
              <p>
                {address.city} {address.state ? `- ${address.state}` : ""}{" "}
                <br />
                {address.street}, {address.number ?? ""}{" "}
                {address.note ? " - " + address.note : ""}{" "}
                {address.neighborhood ? " - " + address.neighborhood : ""},{" "}
                {address.state} - CEP{" "}
                {address.zipCode.substring(0, 5) +
                  "-" +
                  address.zipCode.substring(5)}
              </p>
            </S.ItemBox>
          </Stack>
        )
      }))
    },
    {
      groupName: "Contas Bancárias",
      iconName: "Bank",
      fields: client.checkingAccounts.map((account, index) => ({
        key: account.id,
        value: (
          <Stack
            horizontal
            verticalAlign="center"
            tokens={{ childrenGap: 15, padding: "10px 0 0 0" }}
            style={{
              borderTop: `${
                index > 0 && `1px solid ${theme.palette.neutralQuaternary}`
              }`
            }}
            grow
          >
            <Stack.Item>
              <S.ItemTitle>{`Conta ${index + 1}:`}</S.ItemTitle>
            </Stack.Item>
            <S.ItemBox isItemActive={checkingAccountIdInUse === account.id}>
              {checkingAccountIdInUse === account.id ? (
                <S.ItemInUseTitle>Em uso</S.ItemInUseTitle>
              ) : null}
              <p>Banco {account.bankNumber}</p>
              <p>Agência {account.agencyNumber}</p>
              <p>Número {account.accountNumber}</p>
            </S.ItemBox>
          </Stack>
        )
      }))
    }
  );

  return clientInfo;
}

export function ViewClient({
  contractInfo,
  addressIdInUse,
  checkingAccountIdInUse,
  contactIdInUse,
  documentIdInUse,
  secondaryDocumentIdInUse,
  drawerContainerRef,
  closeDrawer
}: ViewClientProps): JSX.Element {
  const { isLoading, data, cancelRequest, execute } =
    useApiRequest<FieldsProps>({
      apiCall: accountService.get
    });
  const [clientInfo, setClientInfo] = useState<ClientInfoProps[]>(null);

  useEffect(() => {
    execute({
      params: { query: { businessActionId: contractInfo?.businessUnit?.id } },
      args: { id: contractInfo?.account?.id }
    });

    return () => {
      cancelRequest.current = true;
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      const client = buildClientInfo(data, {
        addressIdInUse,
        contactIdInUse,
        documentIdInUse,
        secondaryDocumentIdInUse,
        checkingAccountIdInUse
      });
      setClientInfo(client);
    }
  }, [
    data,
    addressIdInUse,
    contactIdInUse,
    documentIdInUse,
    secondaryDocumentIdInUse,
    checkingAccountIdInUse
  ]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : clientInfo ? (
        <Stack tokens={{ childrenGap: 15 }}>
          {clientInfo.map(client => (
            <Stack tokens={{ childrenGap: 15 }} key={client.groupName}>
              <Text variant={"large"}>
                <Icon iconName={client.iconName} style={{ marginRight: 10 }} />
                {client.groupName}
              </Text>

              <Card>
                <Stack tokens={{ padding: 20, childrenGap: 10 }}>
                  {client.fields.map(field => (
                    <Stack
                      horizontal
                      verticalAlign="center"
                      key={field.key}
                      tokens={{ childrenGap: 5 }}
                    >
                      {field.title && <S.ItemTitle>{field.title}</S.ItemTitle>}
                      {typeof field.value === "string" ? (
                        <span>{field.value}</span>
                      ) : (
                        field.value
                      )}
                    </Stack>
                  ))}
                </Stack>
              </Card>
            </Stack>
          ))}

          <CustomDrawerFooter drawerContainerRef={drawerContainerRef}>
            <DefaultButton
              data-testid="close-drawer-view-client-button"
              onClick={closeDrawer}
            >
              Fechar
            </DefaultButton>
          </CustomDrawerFooter>
        </Stack>
      ) : (
        <Stack horizontalAlign="center" verticalAlign="center">
          <span>Não foi possível encontrar nenhum dado.</span>
        </Stack>
      )}
    </>
  );
}
