import {
  DefaultButton,
  Icon,
  PrimaryButton,
  Shimmer,
  ShimmerElementType,
  Stack,
  TextField
} from "@fluentui/react";
import QRCode from "qrcode.react";
import { useState } from "react";

import { DefaultCardTitle } from "../../DisplayContractInfo/styles";

import { ButtonsWrapper, Container, Section } from "./styles";

import { PaymentMethodType } from "@/common/types/PaymentMethod.types";
import {
  PaymentRequest,
  PaymentRequestStatus
} from "@/common/types/PaymentRequestTypes";
import { Card } from "@/components/Shared/Card";
import { useApi } from "@/core/libs/api/react-query/useApi";

interface PaymentAreaProps {
  paymentType: PaymentMethodType;
  paymentRequest: PaymentRequest;
  canShowConfirmPaymentButton: boolean;
  contractId: number;
  isInsidePanel?: boolean;
}

export const PaymentArea = ({
  paymentType,
  paymentRequest,
  canShowConfirmPaymentButton,
  contractId,
  isInsidePanel
}: PaymentAreaProps) => {
  const [copyCode, setCopyCode] = useState("Copiar código");
  const [isLoadingPayment, setIsLoadingPayment] = useState<boolean>(false);
  const { execute } = useApi();
  const showErrors =
    paymentType === PaymentMethodType.PIX &&
    !paymentRequest.code &&
    !paymentRequest.transactionId;

  const showConfirmPaymentButton =
    canShowConfirmPaymentButton &&
    paymentRequest?.requestStatus !== PaymentRequestStatus.COMPLETED;

  const handleCopyCode = () => {
    navigator.clipboard.writeText(paymentRequest?.code);
    setCopyCode("Código copiado");

    setTimeout(() => {
      setCopyCode("Copiar código");
    }, 2500);
  };

  const showPaymentArea = paymentType !== PaymentMethodType.INVOICE_DEBIT;

  const handleConfirmPayment = async () => {
    setIsLoadingPayment(true);
    try {
      await execute({
        url: `contracts/${contractId}/status`,
        method: "POST",
        params: { interaction: "confirm-payment" },
        showAlert: true,
        messages: {
          error: "Erro ao confirmar pagamento",
          success: "Pagamento confirmado com sucesso"
        }
      });
    } finally {
      setIsLoadingPayment(false);
    }
  };

  const handleOpenChat = () => {
    window.fcWidget.open();
  };

  const renderPaymentSection = (
    type: PaymentMethodType
  ): JSX.Element | null => {
    switch (type) {
      case PaymentMethodType.PIX:
        return (
          <Section>
            <strong>Código Pix para pagamento</strong>
            <p style={{ marginBottom: "0.15rem" }}>
              Encaminhe para o cooperado através de mensagem ou e-mail.
            </p>

            <QRCode
              value={paymentRequest?.code}
              style={{ marginTop: "1rem" }}
            />

            <TextField
              styles={{ root: { width: 300 } }}
              label="Pix Copia e Cola"
              disabled={true}
              readOnly={true}
              defaultValue={paymentRequest?.code}
              value={paymentRequest?.code}
            />

            <ButtonsWrapper>
              <DefaultButton text={copyCode} onClick={() => handleCopyCode()} />

              {showConfirmPaymentButton && (
                <PrimaryButton
                  text="Verificar Pagamento"
                  onClick={() => handleConfirmPayment()}
                />
              )}
            </ButtonsWrapper>
          </Section>
        );
      case PaymentMethodType.CREDIT_CARD:
        return (
          <Section>
            <h2 style={{ fontSize: "16px" }}>
              Solicite o link de pagamento via cartão de crédito em nossa
              central de atendimento.
            </h2>

            <ButtonsWrapper>
              <DefaultButton text="Abrir Chat" onClick={handleOpenChat} />
              {showConfirmPaymentButton && (
                <PrimaryButton
                  text="Confirmar Pagamento"
                  onClick={() => handleConfirmPayment()}
                />
              )}
            </ButtonsWrapper>
          </Section>
        );
      default:
        return null;
    }
  };

  if (showPaymentArea) {
    return (
      <Stack
        verticalFill
        style={{
          width: `${isInsidePanel ? "100%" : "30%"}`,
          marginTop: "10px"
        }}
      >
        <DefaultCardTitle level={4}>
          <Icon iconName="PaymentCard" style={{ marginRight: 10 }} />
          Área de Pagamento
        </DefaultCardTitle>
        <Card
          padding="1.5rem"
          borderRadius="0.75rem"
          height="100%"
          style={{ minHeight: "370px" }}
        >
          {isLoadingPayment ? (
            <Container style={{ flexDirection: "column" }}>
              <Shimmer
                style={{ marginBottom: "1rem", width: "20%" }}
                shimmerElements={[
                  {
                    type: ShimmerElementType.line,
                    height: 250
                  }
                ]}
              />
              <Shimmer
                style={{ marginBottom: "1rem", width: "20%" }}
                shimmerElements={[
                  {
                    type: ShimmerElementType.line,
                    height: 60
                  }
                ]}
              />
            </Container>
          ) : (
            <>
              {showErrors ? (
                <Container>
                  <p style={{ color: "#b62021" }}>
                    Ocorreu um erro ao gerar o código Pix. Por favor, entre em
                    contato com o suporte.
                  </p>
                </Container>
              ) : (
                <Container>{renderPaymentSection(paymentType)}</Container>
              )}
            </>
          )}
        </Card>
      </Stack>
    );
  } else {
    return null;
  }
};
