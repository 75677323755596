import * as Yup from "yup";

import { Utils as SystemUtils } from "@/core/libs/form-builder";

const commonSchema = Yup.object().shape({
  contacts: Yup.array()
    .of(
      Yup.object().shape({
        contactType: Yup.string().required(
          "É obrigatório escolher um tipo de contato."
        ),
        contact: Yup.string().required("Os dados de contato são obrigatórios.")
      })
    )
    .required("Os dados de contato são obrigatórios."),
  addresses: Yup.array()
    .of(
      Yup.object().shape({
        zipCode: Yup.string().required("O código postal é obrigatório."),
        city: Yup.string().required("A cidade é obrigatória."),
        state: Yup.string().required("O estado é obrigatório."),
        street: Yup.string().required("O logradouro é obrigatório."),
        neighborhood: Yup.string().required("O bairro é obrigatório."),
        number: Yup.string().required("O número é obrigatório."),
        note: Yup.string().nullable()
      })
    )
    .required("O código postal é obrigatório.")
});

const checkingAccountsSchema = Yup.object().shape({
  checkingAccounts: Yup.array()
    .of(
      Yup.object().shape({
        bankNumber: Yup.string().required("O número do banco é obrigatório."),
        agencyNumber: Yup.string().required(
          "O número da agência bancária é obrigatório."
        ),
        accountNumber: Yup.string().required("O número da conta é obrigatório.")
      })
    )
    .required("É obrigatório informar uma conta.")
});

export const physicalAccountSchema = commonSchema.concat(
  Yup.object().shape({
    documents: Yup.array()
      .of(
        Yup.object().shape({
          documentType: Yup.string().required(
            "O tipo de documento é obrigatório."
          ),
          documentNumber: Yup.string()
            .required("O documento é obrigatório.")
            .when("documentType", {
              is: documentType => documentType === "CPF",
              then: Yup.string()
                .required("O documento é obrigatório.")
                .min(11, "O CPF precisa ter no mínimo 11 dígitos.")
                .test("cpfValidation", "CPF inválido", value => {
                  if (value) {
                    return SystemUtils.ValidateCPF(value);
                  } else {
                    return true;
                  }
                })
            })
            .when("documentType", {
              is: documentType => documentType === "CNPJ",
              then: Yup.string()
                .required("O documento é obrigatório.")
                .min(14, "O CNPJ precisa ter no mínimo 14 dígitos.")
                .test("cnpjValidation", "CNPJ inválido", value => {
                  if (value) {
                    return SystemUtils.ValidateCNPJ(value);
                  } else {
                    return true;
                  }
                })
            })
        })
      )
      .required("O documento é obrigatório."),
    account: Yup.array().of(
      Yup.object().shape({
        fullName: Yup.string()
          .matches(/^[^'"]*$/, "Aspas não são permitidas.")
          .required("Nome é obrigatório"),
        birthDate: Yup.string().test(
          "age",
          "Você deve ter pelo menos 16 anos de idade",
          function (value) {
            const minAgeDate = new Date();
            minAgeDate.setFullYear(minAgeDate.getFullYear() - 16);
            return new Date(value) <= minAgeDate;
          }
        ),
        genderStatus: Yup.string().required("Selecione uma opção de gênero"),
        maritalStatusId: Yup.string().required("O estado civil é obrigatório."),
        occupation: Yup.string().required("Ocupação é obrigatório")
      })
    )
  })
);

export const legalAccountSchema = commonSchema.concat(
  Yup.object().shape({
    documents: Yup.array()
      .of(
        Yup.object().shape({
          documentType: Yup.string().required(
            "O tipo de documento é obrigatório."
          ),
          documentNumber: Yup.string()
            .required("O documento é obrigatório.")
            .when("documentType", {
              is: documentType => documentType === "CNPJ",
              then: Yup.string()
                .required("O documento é obrigatório.")
                .min(14, "O CNPJ precisa ter no mínimo 14 dígitos.")
                .test("cnpjValidation", "CNPJ inválido", value => {
                  if (value) {
                    return SystemUtils.ValidateCNPJ(value);
                  } else {
                    return true;
                  }
                })
            })
        })
      )
      .required("O documento é obrigatório."),
    legalRepresentatives: Yup.array()
      .of(
        Yup.object().shape({
          fullName: Yup.string()
            .matches(/^[^'"]*$/, "Aspas não são permitidas.")
            .required("Campo Obrigatório."),
          email: Yup.string()
            .email("Email Inválido")
            .required("Campo Obrigatório."),
          document: Yup.string()
            .min(11, "O CPF precisa ter no mínimo 11 dígitos.")
            .test("cpfValidation", "CPF inválido", value => {
              if (value) {
                return SystemUtils.ValidateCPF(value);
              } else {
                return true;
              }
            })
            .required("Campo Obrigatório."),
          birthDate: Yup.string().test(
            "age",
            "Você deve ter pelo menos 16 anos de idade",
            function (value) {
              const minAgeDate = new Date();
              minAgeDate.setFullYear(minAgeDate.getFullYear() - 16);
              return new Date(value) <= minAgeDate;
            }
          ),
          role: Yup.string().nullable()
        })
      )
      .required("Campo Obrigatório.")
  })
);

export function getPhysicalAccountSchema({
  checkingAccountsRequired
}: {
  checkingAccountsRequired: boolean;
}) {
  if (checkingAccountsRequired) {
    return physicalAccountSchema.concat(checkingAccountsSchema);
  }

  return physicalAccountSchema;
}

export function getLegalAccountSchema({
  checkingAccountsRequired
}: {
  checkingAccountsRequired: boolean;
}) {
  if (checkingAccountsRequired) {
    return legalAccountSchema.concat(checkingAccountsSchema);
  }

  return legalAccountSchema;
}
