import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  TextField
} from "@fluentui/react";
import { message } from "antd";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import * as Yup from "yup";

import TagPicture from "./TagPicture";

import { ContractsService, ProductsService } from "@/core/libs/api";

const productsService = ProductsService();
const contractsService = ContractsService();

interface TagReplacementDialogProps {
  item: any;
  service: number;
  businessUnitId: number;
  onSuccess: () => void;
  disabled: boolean;
}

function TagReplacementDialog({
  item,
  service,
  businessUnitId: businessActionId,
  onSuccess,
  disabled
}: TagReplacementDialogProps) {
  const [isDialogHidden, setIsDialogHidden] = useState(true);

  const [inventoryData, setInventoryData] = useState<{
    inventoryId: number;
    serialNumber: string;
  }>(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const replaceItem = useAsyncCallback(
    async () => {
      setErrorMessage(undefined);

      const abortController = new AbortController();
      const { signal } = abortController;

      return contractsService.replaceItem({
        params: { id: item.id, body: { inventory: inventoryData.inventoryId } },
        signal
      });
    },
    {
      onSuccess({ response, error }) {
        if (response.ok) {
          setIsDialogHidden(true);
          message.success("Processo de substituição iniciado");
          onSuccess();
        } else {
          setErrorMessage(error.message);
        }
      },
      onError(error) {
        setErrorMessage(error.message);
      }
    }
  );

  const tagIsCanceled = item?.inventory[0]?.canceledAtStartag;

  return (
    <>
      <PrimaryButton
        id="submit-button"
        text="Substituir"
        onClick={() => setIsDialogHidden(false)}
        disabled={disabled || item.nextStep || tagIsCanceled}
      />

      <Dialog
        hidden={isDialogHidden}
        onDismiss={() => setIsDialogHidden(true)}
        modalProps={{ onDismissed: () => setInventoryData(undefined) }}
        dialogContentProps={
          !inventoryData
            ? {
                type: DialogType.normal,
                title: "Substituição de Tag",
                closeButtonAriaLabel: "Cancelar",
                subText:
                  "Insira o número de série da nova tag, a qual substituirá a atual."
              }
            : {
                type: DialogType.normal,
                title: "Deseja Substituir a Tag?",
                closeButtonAriaLabel: "Cancelar",
                subText:
                  "Confira o novo número de série e confirme a substituição. Essa ação é irreversível."
              }
        }
      >
        {!inventoryData && (
          <Formik
            initialValues={{ serialNumber: "" }}
            validationSchema={Yup.object().shape({
              serialNumber: Yup.string()
                .required("Campo obrigatório")
                .length(16, "O número de série deve ter 16 dígitos")
            })}
            onSubmit={({ serialNumber }, { setSubmitting, setErrors }) => {
              setErrorMessage(undefined);

              const abortController = new AbortController();
              const { signal } = abortController;

              productsService
                .searchProduct({
                  params: {
                    query: {
                      businessActionId,
                      serialNumber,
                      operation: item.operation.id,
                      service
                    }
                  },
                  signal
                })
                .then(({ data, error }) => {
                  if (error) {
                    if (error.status === 400) {
                      return setErrors({
                        serialNumber:
                          "O número de série não existe ou já está sendo utilizado"
                      });
                    }

                    return setErrorMessage(error.message);
                  }

                  setInventoryData({
                    inventoryId: data.inventory.id,
                    serialNumber: data.inventory.serialNumber
                  });
                })
                .finally(() => setSubmitting(false));
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <Stack tokens={{ childrenGap: 15 }}>
                  <Field
                    id="input-serial-number"
                    as={TextField}
                    name="serialNumber"
                    label="Novo número de série"
                    required
                    inputmode="numeric"
                    maxLength={16}
                    errorMessage={touched.serialNumber && errors.serialNumber}
                  />

                  {errorMessage && (
                    <MessageBar
                      messageBarType={MessageBarType.error}
                      isMultiline
                    >
                      {errorMessage}
                    </MessageBar>
                  )}
                </Stack>

                <DialogFooter>
                  {isSubmitting && (
                    <Spinner
                      size={SpinnerSize.xSmall}
                      styles={{ root: { display: "inline-block" } }}
                    />
                  )}
                  <PrimaryButton
                    id="button-submit"
                    type="submit"
                    text="Continuar"
                    disabled={isSubmitting}
                  />
                  <DefaultButton
                    id="cancel-button"
                    onClick={() => setIsDialogHidden(true)}
                    text="Cancelar"
                    disabled={isSubmitting}
                  />
                </DialogFooter>
              </Form>
            )}
          </Formik>
        )}

        {inventoryData && (
          <>
            <Stack tokens={{ childrenGap: 15 }}>
              {inventoryData && (
                <TagPicture serialNumber={inventoryData.serialNumber} />
              )}

              {errorMessage && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline>
                  {errorMessage}
                </MessageBar>
              )}
            </Stack>

            <DialogFooter>
              {replaceItem.loading && (
                <Spinner
                  size={SpinnerSize.xSmall}
                  styles={{ root: { display: "inline-block" } }}
                />
              )}
              <PrimaryButton
                id="button-replace"
                onClick={() => replaceItem.execute()}
                disabled={replaceItem.loading}
                text="Substituir"
              />
              <DefaultButton
                id="button-cancel"
                onClick={() => setIsDialogHidden(true)}
                disabled={replaceItem.loading}
                text="Cancelar"
              />
            </DialogFooter>
          </>
        )}
      </Dialog>
    </>
  );
}

export default TagReplacementDialog;
