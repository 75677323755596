import { ComboBox, IComboBoxOption, Icon, Stack } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";

import { DefaultCardTitle } from "../DisplayContractInfo/styles";

import { SelectContainer } from "./styles";

import { Card } from "@/components/Shared/Card";
import { useApi } from "@/core/libs/api/react-query/useApi";

interface ISalesTeamSeleProps {
  handleChangeSalesTeam: (option: IComboBoxOption) => void;
  salesTeamId: number;
}

interface ISalesTeamSelect {
  key: number;
  text: string;
}

export const SalesTeamSelect = ({
  handleChangeSalesTeam,
  salesTeamId
}: ISalesTeamSeleProps) => {
  const { execute } = useApi();
  const [salesTeamsList, setSalesTeamsList] = useState<Array<ISalesTeamSelect>>(
    []
  );

  const getSalesTeamsFromApi = useCallback(async () => {
    const res = await execute({
      url: "sales-teams",
      notificationMessage:
        "Buscando times de comerciais na página de criação de cooperado"
    });

    let options = [];

    if (res.data) {
      options = res.data.items.map(option => {
        return {
          key: option.id,
          text: `${option.name} ${
            option.parent?.name ? `(${option.parent.name})` : ""
          }`
        };
      });
    }
    setSalesTeamsList(options);
  }, [execute]);

  useEffect(() => {
    getSalesTeamsFromApi();
  }, []);

  return (
    <Stack>
      <DefaultCardTitle level={4}>
        <Icon
          iconName="AddGroup"
          style={{ marginRight: 10, position: "relative", top: 1 }}
        />
        Carteiras comerciais
      </DefaultCardTitle>
      <SelectContainer>
        <Card
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "1.5rem",
            paddingBottom: "2rem",
            maxWidth: "40rem"
          }}
        >
          <ComboBox
            label="Informe em qual carteira comercial o contrato será salvo"
            options={salesTeamsList}
            autoComplete="on"
            useComboBoxAsMenuWidth={true}
            selectedKey={salesTeamId}
            onChange={(_, option) => handleChangeSalesTeam(option)}
            styles={{ root: { maxWidth: "25rem", height: "2.5rem" } }}
          />
        </Card>
      </SelectContainer>
    </Stack>
  );
};
