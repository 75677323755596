export enum PaymentRequestStatus {
  PROCESSING = "PROCESSING",
  CANCELED = "CANCELED",
  FAILED = "FAILED",
  COMPLETED = "COMPLETED",
  PENDING_INTEGRATION = "PENDING_INTEGRATION",
  REFUNDED = "REFUNDED"
}

export enum PaymentRequestTypes {
  SETUP = "SETUP",
  MONTHLY = "MONTHLY"
}

export enum PaymentRequestTypeProviders {
  PIX = "PIX",
  CREDIT_CARD = "CREDIT_CARD",
  INVOICE_DEBIT = "INVOICE_SDEBIT"
}
export interface PaymentRequest {
  id: number;
  status: boolean;
  contractId: number;
  requestStatus: PaymentRequestStatus;
  value: number;
  transactionId: string;
  typeProvider: PaymentRequestTypeProviders;
  code: string;
  type: PaymentRequestTypes;
  expiresAt: Date;
}
