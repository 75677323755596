import { Icon, Stack } from "@fluentui/react";

import { DefaultCardTitle } from "../../DisplayContractInfo/styles";

import { IInvoiceInfos } from "./interfaces";
import { InvoiceMonthly } from "./InvoiceMonthly";
import { InvoicePlans } from "./InvoicePlans";
import { InvoiceSetup } from "./InvoiceSetup";
import {
  InvoiceContainer,
  Container,
  ItemsContainer,
  HeaderContainer
} from "./styles";

interface InvoiceProps {
  invoiceInfos: IInvoiceInfos;
  paymentMethodName: string;
  showInvoicePlans?: boolean;
  isInsidePanel?: boolean;
}

export function Invoice({
  invoiceInfos,
  paymentMethodName,
  showInvoicePlans = false,
  isInsidePanel
}: InvoiceProps): JSX.Element {
  const setupInstallments = invoiceInfos?.setup.installments;

  return (
    <Stack
      style={{
        width: `${isInsidePanel ? "100%" : "70%"}`,
        marginTop: "10px",
        flex: "1"
      }}
    >
      <DefaultCardTitle level={4}>
        <Icon iconName="Money" style={{ marginRight: 10 }} />
        Resumo dos Pagamentos
      </DefaultCardTitle>
      <InvoiceContainer>
        <Container>
          <ItemsContainer>
            {showInvoicePlans && <InvoicePlans plans={invoiceInfos?.plans} />}

            <InvoiceSetup setup={invoiceInfos?.setup} title="Valor a Pagar" />

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                marginTop: "1rem",
                gap: "6rem"
              }}
            >
              <InvoiceMonthly
                installments={invoiceInfos?.installments}
                title={"Fatura - Valor Mensal"}
              />

              {setupInstallments?.length > 0 && (
                <InvoiceMonthly
                  installments={setupInstallments}
                  title={`Pagamento via: ${paymentMethodName} (${setupInstallments?.length}x)`}
                  installmentText="ª Parcela"
                />
              )}
            </div>
          </ItemsContainer>
        </Container>
      </InvoiceContainer>
    </Stack>
  );
}
