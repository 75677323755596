import {
  ColumnActionsMode,
  getTheme,
  IColumn,
  IconButton,
  SelectionMode,
  Separator,
  Stack
} from "@fluentui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { getAndSetUsers } from "../Settings/pages/BusinessUnits/commonAPIRequest";

import { dismissMessage } from "./actions/contracts";
import { requestOperations } from "./api";
import {
  ContractBadgeColors,
  ContractStatus,
  IconNames,
  TranslatedContractCurrentStep,
  TranslatedContractNextStep
} from "./enums";
import * as S from "./styles";
import { ContractProps } from "./types";

import { FilterParamsType } from "@/common/types/ListFromApi.types";
import {
  PaymentMethodType,
  TranslatedPaymentMethodType
} from "@/common/types/PaymentMethod.types";
import { ViewContract } from "@/components/Contracts/ViewContract";
import { GoTo } from "@/components/goTo";
import { Card } from "@/components/Shared/Card";
import {
  CustomDrawer,
  DrawerActions,
  DrawerActionsType,
  DrawerModules
} from "@/components/Shared/CustomDrawer";
import { useGetContracts } from "@/core/libs/api/react-query/hook/use-get-contracts";
import { useApi } from "@/core/libs/api/react-query/useApi";
import { ListData } from "@/core/libs/list-data";
import { FiltersTypeEnum } from "@/core/libs/list-data/lib/enums";
import { FiltersProps } from "@/core/libs/list-data/lib/types";
import { useSelectedBu } from "@/hooks/useSelectedBu";
import { useURLFilter } from "@/hooks/useUrlFIlter";
import { AccountDocumentType } from "@/modules/Accounts/types";
import { cpfCnpjFormatter } from "@/utils/FormatCPForCNPJ";

const theme = getTheme();

const removeMask = (value: string) => {
  return value?.replace(/\D/g, "");
};

// In the back-end code, filters with CANCELED, REJECTED, IN_PROGRESS and DONE
// considers currentStep.
const contractStatusOptions = [
  {
    key: ContractStatus.IN_PROGRESS,
    text: TranslatedContractCurrentStep.IN_PROGRESS
  },
  {
    key: ContractStatus.DOCUMENTS_SENT,
    text: TranslatedContractNextStep.DOCUMENTS_SENT
  },
  {
    key: ContractStatus.DOCUMENTS_APPROVED,
    text: TranslatedContractNextStep.DOCUMENTS_APPROVED
  },
  {
    key: ContractStatus.CREDIT_APPROVED,
    text: TranslatedContractNextStep.CREDIT_APPROVED
  },
  {
    key: ContractStatus.COMMERCIALLY_APPROVED,
    text: TranslatedContractNextStep.COMMERCIALLY_APPROVED
  },
  {
    key: ContractStatus.CLIENT_SIGNED,
    text: TranslatedContractNextStep.CLIENT_SIGNED
  },
  {
    key: ContractStatus.OPTIONAL_SIGNATURES_COLLECTED,
    text: TranslatedContractNextStep.OPTIONAL_SIGNATURES_COLLECTED
  },
  {
    key: ContractStatus.DELIVERED,
    text: TranslatedContractNextStep.DELIVERED
  },
  {
    key: ContractStatus.DONE,
    text: TranslatedContractCurrentStep.DONE
  },
  {
    key: ContractStatus.TIMED_OUT,
    text: TranslatedContractNextStep.TIMED_OUT
  },
  {
    key: ContractStatus.REJECTED,
    text: TranslatedContractCurrentStep.REJECTED
  },
  {
    key: ContractStatus.CANCELED,
    text: TranslatedContractCurrentStep.CANCELED
  },
  {
    key: ContractStatus.SETUP_PAID,
    text: TranslatedContractCurrentStep.SETUP_PAID
  }
];

const paymentMethodOptions = [
  {
    key: PaymentMethodType.CREDIT_CARD,
    text: TranslatedPaymentMethodType.CREDIT_CARD
  },
  {
    key: PaymentMethodType.INVOICE_DEBIT,
    text: TranslatedPaymentMethodType.INVOICE_DEBIT
  },
  {
    key: PaymentMethodType.PIX,
    text: TranslatedPaymentMethodType.PIX
  }
];

const loadingDropdownOptions = [
  { key: "loading", text: "Carregando...", disabled: true }
];

function Contracts({
  canRead,
  canCreate,
  canEdit
}: ContractProps): JSX.Element {
  const drawerContainerRef = useRef<HTMLDivElement>(null);
  const [drawerState, setDrawerState] = useState({
    action: null,
    isOpen: false
  });
  const [selectedItemId, setSelectedItemId] = useState<number>(undefined);
  const [pendingContractOptions, setPendingContractOptions] =
    useState<{ text: string; queryValue: string; count: string }[]>(null);
  const [contractOperationsOptions, setContractOperationsOptions] =
    useState(null);
  const [salesTeamsOptions, setSalesTeamsOptions] = useState(null);
  const [usersFilterOptions, setUserFilterOptions] = useState(null);
  const { changeUrlParams, urlParams } = useURLFilter();
  const history = useHistory();
  const { execute } = useApi();

  const { contractsList, invalidateQuery } = useGetContracts(urlParams.params);

  const { selectedBU } = useSelectedBu();

  const dispatch = useDispatch();

  const [pendingList, setPendingList] = useState(null);

  const filters: Array<FiltersProps> = useMemo(
    () => [
      {
        label: "Código",
        placeholder: "Código do contrato",
        filterQuery: "code",
        type: FiltersTypeEnum.TEXT,
        inputType: "number"
      },
      {
        label: "Nome",
        placeholder: "Nome do cooperado",
        filterQuery: "fullName",
        type: FiltersTypeEnum.TEXT
      },
      {
        label: "Documento",
        placeholder: "CPF/CNPJ",
        filterQuery: "documentNumber",
        type: FiltersTypeEnum.TEXT,
        maskFormatter: cpfCnpjFormatter,
        valueFormatter: removeMask,
        maxLength: 18
      },
      {
        label: "Estado",
        placeholder: "Estado",
        filterQuery: "contractStatus",
        type: FiltersTypeEnum.SELECT,
        options: contractStatusOptions,
        minWidth: "300px"
      },
      {
        label: "Método de Pagamento",
        placeholder: "Método de Pagamento",
        filterQuery: "paymentMethod",
        type: FiltersTypeEnum.SELECT,
        options: paymentMethodOptions,
        minWidth: "220px"
      },
      {
        label: "Operação",
        placeholder: "Operação",
        filterQuery: "operation",
        type: FiltersTypeEnum.SELECT,
        options: contractOperationsOptions ?? loadingDropdownOptions,
        minWidth: "300px"
      },
      {
        label: "Linha",
        filterQuery: "lineNumber",
        type: FiltersTypeEnum.MASK_TEXT,
        mask: "(99) 99999-9999",
        valueFormatter: removeMask
      },
      {
        label: "Número de Série",
        placeholder: "Número de Série",
        filterQuery: FiltersTypeEnum.SERIAL_NUMBER,
        type: FiltersTypeEnum.TEXT,
        inputType: "number"
      },
      {
        label: "Contratos criados entre",
        filterQuery: ["beginsAt", "finishesAt"],
        type: FiltersTypeEnum.DATE_RANGE
      },
      {
        label: "Tipo de listagem",
        filterQuery: "me",
        type: FiltersTypeEnum.SELECT,
        defaultValue: "false",
        options: [
          {
            key: "false",
            text: "Todos os contratos"
          },
          {
            key: "true",
            text: "Apenas os meus contratos"
          }
        ],
        minWidth: "205px"
      },
      {
        label: "Time comercial",
        placeholder: "Time comercial",
        filterQuery: "salesTeam",
        type: FiltersTypeEnum.SELECT,
        options: salesTeamsOptions ?? loadingDropdownOptions,
        inputType: "select"
      },
      {
        label: "Usuário",
        placeholder: "Usuário",
        filterQuery: "userId",
        type: FiltersTypeEnum.SELECT,
        options: usersFilterOptions ?? loadingDropdownOptions,
        inputType: "select",
        minWidth: "200px"
      },
      {
        label: "Tag Cancelada",
        filterQuery: "canceledTag",
        type: FiltersTypeEnum.CHECKBOX,
        inputType: "checkbox"
      }
    ],
    [contractOperationsOptions, salesTeamsOptions, usersFilterOptions]
  );

  const handleContractsRequest = useCallback(
    (filterParams?: FilterParamsType) => {
      setPendingList(true);
      changeUrlParams({
        ...filterParams,
        businessUnitSelected: selectedBU.id ?? null
      });
      setPendingList(false);
    },
    [changeUrlParams, selectedBU]
  );

  const listAndSetSalesTeam = useCallback(async () => {
    const result = await execute({
      url: `sales-teams`,
      notificationMessage: "Buscando Times comerciais no dashboard de contratos"
    });

    if (result.data) {
      const formattedSalesTeams = result.data.items.map(org => {
        return { key: org.id, text: org.name };
      });

      setSalesTeamsOptions(formattedSalesTeams);
    }
  }, []);

  useEffect(() => {
    requestOperations({
      selectedBUId: selectedBU?.id,
      setContractOperationsOptions
    });
    listAndSetSalesTeam();
    getAndSetUsers({ setUserFilterOptions });
  }, [selectedBU?.id]);

  useEffect(() => {
    if (contractsList?.pendingContracts) {
      const formattedPendingContractOptions =
        contractsList.pendingContracts.map(contract => ({
          text: TranslatedContractNextStep[contract.internalName],
          iconName: IconNames[contract.internalName],
          queryValue: contract.internalName,
          count: contract.qty
        }));

      setPendingContractOptions(formattedPendingContractOptions);
    }
  }, [contractsList]);

  function handleDismissMessage() {
    dispatch(dismissMessage());
  }

  function openDrawer(action: DrawerActionsType) {
    setDrawerState({ action: action, isOpen: true });
  }

  function closeDrawer() {
    setDrawerState({ action: null, isOpen: false });
  }

  return (
    <Stack styles={{ root: { width: "100%" } }}>
      <Helmet>
        <title>Contratos - Libertas</title>
      </Helmet>

      <Stack tokens={{ childrenGap: 15 }}>
        <Stack horizontal horizontalAlign="space-between">
          <h2 className="ms-fontSize-24">Contratos</h2>
          <GoTo route="contracts" label="Ir para contrato" />
        </Stack>

        <Card>
          <ListData
            headerAction={
              canCreate ? () => history.push("/contracts/create") : undefined
            }
            headerText="Contrato"
            isLoadingList={pendingList}
            items={contractsList?.contracts || []}
            selectionMode={SelectionMode.none}
            columns={buildColumns({
              canRead,
              canEdit,
              openDrawer,
              setSelectedItemId,
              history
            })}
            hasFilters
            isDefaultFilterVisible
            filters={filters}
            headerFilterQueryName="pendingStatus"
            headerFilters={pendingContractOptions}
            menuItems={undefined}
            setSelectedItem={setSelectedItemId}
            _paginate={handleContractsRequest}
          />
        </Card>

        <CustomDrawer
          isOpen={drawerState.isOpen}
          action={drawerState.action}
          module={DrawerModules.CONTRACT}
          onCloseDrawer={closeDrawer}
          size="medium"
          drawerContainerRef={drawerContainerRef}
        >
          {drawerState.action === DrawerActions.VIEW && canRead && (
            <ViewContract
              initialContractId={selectedItemId}
              currentContractList={
                Array.isArray(contractsList?.contracts)
                  ? contractsList.contracts
                  : contractsList?.contracts?.["items"]
              }
              currentPage={
                !Array.isArray(contractsList?.contracts) &&
                contractsList?.contracts?.["meta"]["currentPage"]
              }
              totalPages={
                !Array.isArray(contractsList?.contracts) &&
                contractsList?.contracts?.["meta"]["totalPages"]
              }
              listContractRequest={handleContractsRequest}
              dismissMessage={handleDismissMessage}
              closeDrawer={closeDrawer}
              drawerContainerRef={drawerContainerRef}
            />
          )}
        </CustomDrawer>
      </Stack>
    </Stack>
  );
}

function buildColumns({
  canRead,
  canEdit,
  openDrawer,
  setSelectedItemId,
  history
}) {
  let isInProgress;
  const hasAction: boolean =
    (canEdit && isInProgress) || (!isInProgress && canRead);

  const columns: IColumn[] = [
    {
      key: "id",
      name: "Código",
      minWidth: 20,
      maxWidth: 50,
      fieldName: "id",
      isRowHeader: true,
      data: "string"
    },
    {
      key: "account",
      name: "Cooperado",
      minWidth: 200,
      fieldName: "account",
      isRowHeader: true,
      data: "string",
      isPadded: true,
      isMultiline: true,
      onRender: item => {
        isInProgress = item.currentStep?.internalName === "IN_PROGRESS";
        const contractPath = `/contracts/${item.id}${
          isInProgress ? "/edit" : ""
        }`;

        return (
          <S.ClickableDiv
            onClick={() => (hasAction ? history.push(contractPath) : null)}
            width="auto"
          >
            {item.account?.fullName ?? (
              <S.Badge
                bgColor="transparent"
                fontWeight="600"
                fontColor={ContractBadgeColors.DISABLED}
                border={`1px solid ${ContractBadgeColors.DISABLED}`}
              >
                Nenhum cooperado selecionado
              </S.Badge>
            )}
            {item.accountDocument ? (
              <S.DocumentInfoSpan>
                {item.accountDocument.documentType}{" "}
                {item.accountDocument.documentType ===
                  AccountDocumentType.CPF ||
                item.accountDocument.documentType === AccountDocumentType.CNPJ
                  ? cpfCnpjFormatter(item.accountDocument.documentNumber)
                  : item.accountDocument.documentNumber}
              </S.DocumentInfoSpan>
            ) : null}
          </S.ClickableDiv>
        );
      }
    },
    {
      key: "items",
      name: "Itens",
      minWidth: 50,
      maxWidth: 50,
      fieldName: "items",
      isRowHeader: true,
      onRender: item => {
        const contractPath = `/contracts/${item.id}${
          isInProgress ? "/edit" : ""
        }`;

        return (
          <S.ClickableDiv
            onClick={() => (hasAction ? history.push(contractPath) : null)}
          >
            {item.items}
          </S.ClickableDiv>
        );
      }
    },
    {
      key: "nextStep",
      name: "Estado",
      minWidth: 160,
      maxWidth: 160,
      fieldName: "nextStep",
      isRowHeader: true,
      isPadded: true,
      isMultiline: true,
      onRender: item => {
        return (
          <S.Badge
            bgColor="transparent"
            display="flex"
            flexGrow="1"
            justifyContent="center"
            wordWrap="break-word"
            whiteSpace="unset"
            fontWeight="600"
            fontColor={
              ContractBadgeColors[item.currentStep.internalName] ??
              ContractBadgeColors.DEFAULT
            }
            border={`1px solid ${
              ContractBadgeColors[item.currentStep.internalName] ??
              ContractBadgeColors.DEFAULT
            }`}
          >
            {item.nextStep?.friendlyName ??
              TranslatedContractCurrentStep[item.currentStep.internalName]}
          </S.Badge>
        );
      }
    },
    {
      key: "infos",
      name: "Informações",
      minWidth: 200,
      maxWidth: 200,
      fieldName: "infos",
      isRowHeader: true,
      isMultiline: true,
      onRender: item => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              className="ms-fontWeight-semibold"
              style={{
                color: theme.palette.neutralTertiary
              }}
            >
              {item.businessUnit?.parent?.name}
            </span>
            <span className="ms-fontSize-12">
              {item.businessUnit.name.toUpperCase()}
            </span>

            <Separator />

            <span className="ms-fontSize-12">
              <span style={{ fontWeight: 600 }}>Autor:</span>{" "}
              {item.user.fullName}
            </span>
          </div>
        );
      }
    },
    {
      key: "createdAt",
      name: "Data",
      fieldName: "createdAt",
      isRowHeader: true,
      minWidth: 100,
      data: "string",
      onRender: ({ createdAt }) => {
        if (createdAt) {
          return (
            <div>
              {new Intl.DateTimeFormat("pt-BR").format(new Date(createdAt))}
            </div>
          );
        }
      }
    }
  ];

  if (canRead) {
    columns.unshift({
      key: "view",
      name: "view",
      fieldName: "view",
      minWidth: 60,
      maxWidth: 60,
      columnActionsMode: ColumnActionsMode.disabled,
      isIconOnly: true,
      onRender: item => {
        const isContractInProgress =
          item.currentStep.internalName === "IN_PROGRESS";

        return (
          <IconButton
            onClick={() => {
              if (!isContractInProgress) {
                setSelectedItemId(item.id);
                openDrawer(DrawerActions.VIEW);
              }
            }}
            iconProps={{
              iconName: isContractInProgress ? "Hide3" : "View"
            }}
            style={{
              color: isContractInProgress ? theme.palette.neutralTertiary : "",
              cursor: isContractInProgress ? "not-allowed" : ""
            }}
            title={
              isContractInProgress
                ? "Visualização Rápida Não Disponível"
                : "Visualizar Contrato"
            }
          />
        );
      }
    });
  }

  return columns;
}

export default Contracts;
