import {
  Dialog,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  DialogType
} from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
import { Stack } from "@fluentui/react/lib/Stack";
import { useEffect, useState } from "react";

import { DefaultCardTitle } from "../styles";

import SignatureTable from "./SignatureTable";

import { Card } from "@/components/Shared/Card";
import { ContractsService } from "@/core/libs/api";

const contractsService = ContractsService();

interface ContractSignaturesProps {
  contractId: string;
  eletronicSignature?: any;
}

export function ContractSignatures({
  contractId,
  eletronicSignature
}: ContractSignaturesProps) {
  const [canResendSignatureRequest, setCanResendSignatureRequest] = useState(
    () =>
      eletronicSignature?.document?.signers?.some(
        signer => signer.sign_as === "transferee" && !signer.signature
      )
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    setCanResendSignatureRequest(
      eletronicSignature?.document?.signers?.some(
        signer => signer.sign_as === "transferee" && !signer.signature
      )
    );
  }, [eletronicSignature]);

  function showConfirmResendSignatureRequest() {
    setIsDialogOpen(true);
  }

  function handleResend() {
    const { signal } = new AbortController();

    contractsService
      .resendSignatures({ id: contractId, signal })
      .then(({ error, data }) => {
        if (error) {
          // handle error (e.g., show notification)
          console.error(
            "Não foi possível reenviar assinaturas pendentes",
            error.message
          );
        } else {
          // handle success (e.g., show notification)
          console.log("Assinaturas reenviadas com sucesso!");
        }
      })
      .catch(error => {
        // handle error
        console.error("Não foi possível reenviar assinaturas pendentes", error);
      })
      .finally(() => {
        setIsDialogOpen(false);
      });
  }

  return (
    <Stack verticalFill>
      <Stack horizontal horizontalAlign="space-between">
        <DefaultCardTitle level={4}>
          <Icon iconName="OpenEnrollment" style={{ marginRight: 10 }} />
          Assinaturas
        </DefaultCardTitle>
        <DefaultButton
          data-testid="resend-documents-button"
          disabled={!canResendSignatureRequest}
          onClick={showConfirmResendSignatureRequest}
        >
          Reenviar
        </DefaultButton>
      </Stack>

      <Card borderRadius="1rem" padding="1rem">
        <SignatureTable signatures={eletronicSignature} />
      </Card>

      <Dialog
        isOpen={isDialogOpen}
        onDismiss={() => setIsDialogOpen(false)}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: "Confirmação",
          showCloseButton: true,
          subText:
            "Você tem certeza que deseja reenviar as assinaturas pendentes?"
        }}
      >
        <DialogFooter>
          <DefaultButton onClick={() => setIsDialogOpen(false)}>
            Cancelar
          </DefaultButton>
          <PrimaryButton onClick={handleResend}>Reenviar</PrimaryButton>
        </DialogFooter>
      </Dialog>
    </Stack>
  );
}
