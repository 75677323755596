import { TextField } from "@fluentui/react";
import { Space } from "antd";

import { FilterComponentProps } from "../types";

import { isObjectEmpty } from "@/utils/IsObjectEmpty";

export function TextFilter({
  filterConfigs,
  selectedTerms,
  visibleTerms,
  setVisibleTerms,
  setSelectedTerms
}: FilterComponentProps): JSX.Element {
  return (
    <Space direction="vertical" size="small">
      <TextField
        disabled={filterConfigs.disabled ? true : false}
        placeholder={filterConfigs.placeholder}
        label={filterConfigs.label}
        type={filterConfigs.type}
        onChange={event => {
          const eventValue = event.currentTarget?.value.trimStart();
          setVisibleTerms(prevState => {
            return {
              ...prevState,
              [filterConfigs.filterQuery as string]: eventValue
            };
          });

          const conditionalSearch = filterConfigs.customConditional
            ? filterConfigs.customConditional(event.currentTarget?.value)
            : event.currentTarget?.value && event.currentTarget?.value !== "";
          if (conditionalSearch) {
            setSelectedTerms(prevState => {
              return {
                ...prevState,
                [filterConfigs.filterQuery as string]:
                  filterConfigs.valueFormatter
                    ? filterConfigs.valueFormatter(eventValue)
                    : eventValue
              };
            });
          } else if (
            !isObjectEmpty(selectedTerms) &&
            selectedTerms[filterConfigs.filterQuery as string]
          ) {
            setSelectedTerms(prevState => {
              delete prevState[filterConfigs.filterQuery as string];
              return {
                ...prevState
              };
            });
          }
        }}
        multiline={filterConfigs?.multiLine ?? null}
        value={
          filterConfigs.maskFormatter
            ? filterConfigs.maskFormatter(
                visibleTerms[filterConfigs.filterQuery as string] ?? ""
              )
            : visibleTerms[filterConfigs.filterQuery as string] ?? ""
        }
        maxLength={filterConfigs.maxLength}
        style={{
          maxWidth: filterConfigs?.maxWidth,
          minWidth: filterConfigs?.minWidth
        }}
      />
    </Space>
  );
}
