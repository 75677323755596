import { Icon, PrimaryButton } from "@fluentui/react";

import { InfoCamp } from "../InfoCamp";

import { InfoContainer, TitleContainer, Wrapper } from "./styles";

import { DefaultCardTitle } from "@/components/Contracts/DisplayContractInfo/styles";
import { Card } from "@/components/Shared/Card";

interface IShowInfoCard {
  iconName: string;
  title: string;
  openDrawer?: () => void;
  info: unknown;
  isButtonDisabled?: boolean;
}

export const ShowInfoCard = ({
  iconName,
  title,
  openDrawer,
  info,
  isButtonDisabled
}: IShowInfoCard) => {
  const infoNameList = Object.keys(info);

  return (
    <Wrapper>
      <TitleContainer>
        <DefaultCardTitle level={4}>
          <Icon iconName={iconName} style={{ marginRight: 10 }} />
          {title}
        </DefaultCardTitle>
        {openDrawer ? (
          <PrimaryButton
            id="view-client-button"
            onClick={openDrawer}
            disabled={isButtonDisabled}
          >
            Editar
          </PrimaryButton>
        ) : null}
      </TitleContainer>
      <Card width="100%" height="100%">
        <InfoContainer>
          {infoNameList.map((data, index) => {
            if (info && info[data]) {
              const currentAccountInfo = info[data];
              const isArrayOfArray = Array.isArray(currentAccountInfo[0]);

              if (currentAccountInfo.length === 0) return null;

              if (isArrayOfArray) {
                return currentAccountInfo?.map((dtValue, idx) => {
                  return <InfoCamp key={idx} title={data} value={dtValue} />;
                });
              }

              return (
                <InfoCamp key={index} title={data} value={currentAccountInfo} />
              );
            }
          })}
        </InfoContainer>
      </Card>
    </Wrapper>
  );
};
