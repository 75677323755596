import { Modal } from "antd";
import { useState } from "react";
import * as Yup from "yup";

import { ContractsService } from "@/core/libs/api";
import FormBuilder from "@/core/libs/form-builder";
import { messageBarTypes } from "@/core/libs/message-bar";
import { fields } from "@/modules/Contracts/fields/fields";

interface DialogProps {
  requestContract: () => Promise<any>;
  closeDocumentModal: () => void;
  onSubmit?: () => void;
  isVisibleDocumentModal: boolean;
  setAttachments?: any;
  setUploads: any;
  contract: any;
  match;
}

const schema = Yup.object().shape({
  files: Yup.string().required("Campo obrigatório.")
});

export function UploadDocumentModal({
  closeDocumentModal,
  onSubmit,
  requestContract,
  isVisibleDocumentModal,
  setUploads,
  match
}: DialogProps): JSX.Element {
  const uploadDocumentFormId = "upload-document-form";
  const [documentsMessage, setDocumentsMessage] = useState(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  function clearMessage() {
    setDocumentsMessage(null);
  }

  async function requestAndSetUploads() {
    try {
      const response = await requestContract();
      setUploads(response.data.uploads);

      setTimeout(() => {
        clearMessage();
        closeDocumentModal();
      }, 1000);
    } catch {
      setDocumentsMessage({
        message:
          "Não foi possível localizar o contrato, por favor atualize a página e tente novamente",
        type: messageBarTypes.ERROR
      });
    }
  }

  async function uploadDocument({ contractId, setSubmitting, data }) {
    const abortController = new AbortController();
    const { signal } = abortController;
    const service = ContractsService();

    const params = {
      id: contractId,
      body: data
    };

    const response = await service.upload({ params, signal });

    if (response.error) {
      setDocumentsMessage({
        message:
          response.error.message ?? "Não foi possível anexar, tente novamente",
        type: messageBarTypes.ERROR
      });
    } else {
      setDocumentsMessage({
        message: "Documento(s) anexados(s) com sucesso.",
        type: messageBarTypes.SUCCESS
      });
    }
  }

  async function handleSubmitForm(values, { setSubmitting }) {
    if (onSubmit) {
      onSubmit();
      setTimeout(() => {
        setSubmitting(false);
      }, 1000);

      return;
    }

    if (values.files?.length > 0) {
      clearMessage();
      setIsUploading(true);
      for (const file of values.files) {
        if (
          !file?.attachmentType &&
          !fields.uploadDocument.find(f => !f.attachmentTypeConfig)
        ) {
          setDocumentsMessage({
            message: "Selecione o tipo do anexo e tente novamente.",
            type: messageBarTypes.ERROR
          });
          setIsUploading(false);
          setSubmitting(false);
          return;
        }
      }
      const dataToSubmit = new FormData();

      values.files.forEach(file => {
        dataToSubmit.append("files[]", file);
        dataToSubmit.append("attachmentType", file.attachmentType);
      });
      await uploadDocument({
        setSubmitting,
        data: dataToSubmit,
        contractId: match.params.id
      });
      requestAndSetUploads();
      setIsUploading(false);
      setSubmitting(false);
    }
  }

  return (
    <Modal
      onCancel={closeDocumentModal}
      okButtonProps={{
        form: uploadDocumentFormId,
        htmlType: "submit",
        id: "button-submit-documents"
      }}
      cancelButtonProps={{ id: "button-cancel-upload-documents" }}
      visible={isVisibleDocumentModal}
      confirmLoading={isUploading}
      title="Anexar documento(s) ao contrato"
      // avoid render inside body tag, it's cause page overflow
      getContainer="#root"
      cancelText="Cancelar"
      okText="Anexar"
      destroyOnClose
      centered
    >
      <div style={{ maxWidth: 450 }}>
        <FormBuilder
          dismissMessage={clearMessage}
          onSubmit={handleSubmitForm}
          dismissPanel={closeDocumentModal}
          formId={uploadDocumentFormId}
          fields={fields.uploadDocument}
          message={documentsMessage}
          validationSchema={schema}
        />
      </div>
    </Modal>
  );
}
