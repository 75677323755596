import { MutableRefObject, ReactNode, useEffect, useState } from "react";
import ReactDOM from "react-dom";

type CustomDrawerFooterProps = {
  children: ReactNode;
  /**
   * Drawer Container reference
   */
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
};

export function CustomDrawerFooter({
  children,
  drawerContainerRef
}: CustomDrawerFooterProps): JSX.Element {
  const [drawerRef, setDrawerRef] = useState<Element | null>(null);

  useEffect(() => {
    if (!drawerRef && drawerContainerRef) {
      const [elementRef] = drawerContainerRef.current.getElementsByClassName(
        "ant-drawer-wrapper-body"
      );

      if (elementRef) {
        setDrawerRef(elementRef);
      }
    }
  }, [drawerContainerRef, drawerRef]);

  if (!drawerRef) return null;

  return ReactDOM.createPortal(
    <div className="ant-drawer-footer">{children}</div>,
    drawerRef
  );
}
